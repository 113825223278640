import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { Link } from "gatsby"
import BlogPostsPagination from "../components/blog/BlogPostsPagination"

const AuthorPage = (props) => {
  const { authorDetails } = props.data
  const { name, avatar, description, posts } = authorDetails
  return (
    <Layout>
      <div className="bg-[#0043DF] py-8 header_bg relative overflow-hidden ">
        <div className="max-w-7xl mx-auto px-4 md:py-16 py-8  mt-8" >
          <div className="w-full z-30 relative flex sm:flex-row flex-col items-center gap-10">
            <div>
              <div className="w-48 h-48 rounded-full border-[6px] border-white">
                <img src={avatar?.url} alt={name} className="rounded-full m-0 p-0 w-full" />
              </div>
            </div>
            <div>
              <h3 className="text-base font1 text-[#fde047] uppercase">About Author</h3>
              {name && <h1 className="shrink py-4 text-white font1 md:text-5xl text-3xl tracking-tight mb-0">{name}</h1>}
              <p className="md:text-2xl text-xl text-white max-w-full md:leading-[40px]">{description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 md:py-16 py-8">                
          <BlogPostsPagination data={posts.nodes} name={name} />
      </div>
    </Layout>
  )
}

export const Head = props => {
  const { authorDetails } = props.data
  return (
    <Seo
      title={authorDetails?.name}
      description={authorDetails?.name}
    />
  )
}

export const query = graphql`
  query getAuthorDetails($id: String!) { 
    authorDetails:  wpUser(id: {eq: $id}) {
      name
      slug
      avatar {
        url
      }
      description
      posts {
          nodes {
            ...WpPostFragment
          }
      }
    }
  }
`

export default AuthorPage